import { THGCarStatus } from '@/enums/thg/THGCarStatus';
import { THGVehicleClass } from '@/enums/thg/THGVehicleClass';
import { THGUserType } from '@/enums/thg/THGUserType';
import { THGCar } from '@/interfaces/thg/THGCar';
import { THGUpdateCarData } from '@/interfaces/thg/THGUpdateCarData';
import { THGUser } from '@/interfaces/thg/THGUser';
import { THGUserUpdateData } from '@/interfaces/thg/THGUserUpdateData';
import { formatDate } from '@/utils/misc';
import { THGCarUpdateStatusPayload } from '@/interfaces/thg/THGCarUpdateStatusPayload';
import { Country } from '@/enums/Country';
import { THGInfoMailPayload } from '@/interfaces/thg/THGInfoMailPayload';
import {
    THGCarOverrideCO2EquivalentPayload,
    THGCarOverrideStatusPayload,
} from '@/interfaces/thg/THGCarActions';
import { THGChargingPlugType } from '@/enums/thg/THGChargingPlugType';
import { THGCarFilterParam } from '@/interfaces/thg/THGCarFilterParam';
import { THGUserFilterParam } from '@/interfaces/thg/THGUserFilterParam';
import { THGPromotionStatus } from '@/enums/thg/THGPromotionStatus';
import { useTHGGlobalStore } from '@/storenew/thg/global';

/**
 * Map the thg user type to german text.
 *
 * @param type
 * @return text
 */
export function getUserTypeText(type: THGUserType) {
    switch (type) {
        case THGUserType.PRIVATE:
            return 'privat';
        case THGUserType.COMPANY:
            return 'Unternehmen';
    }
}

/**
 * Map the THGPromotionStatus enum to German text.
 *
 * @param status
 * @return text
 */
export function getPromotionStatusText(status: THGPromotionStatus) {
    if (status === THGPromotionStatus.PAID_OUT) {
        return 'Ausbezahlt';
    }

    return 'Offen';
}

/**
 * Map the CarStatus enum to german text.
 *
 * @param status
 * @return text
 */
export function getCarStatusText(status: THGCarStatus): string {
    switch (status) {
        case THGCarStatus.PRE_RESERVED:
            return 'Vorreserviert';
        case THGCarStatus.PRE_RESERVATION_DELETED:
            return 'Vorreservierung gelöscht';
        case THGCarStatus.DATA_RECEIVED:
            return 'Daten erhalten';
        case THGCarStatus.REG_DECLINED:
            return 'Registrierung abgelehnt';
        case THGCarStatus.UBA_SUBMITTED:
            return 'Beim UBA eingereicht';
        case THGCarStatus.CERT_RECEIVED:
            return 'Zertifikat erhalten';
        case THGCarStatus.CERT_DECLINED:
            return 'Zertifikat abgelehnt';
        case THGCarStatus.INCOME_PAID_OUT:
            return 'Erlös ausbezahlt/gespendet';
    }
}

/**
 * Get the vehicle class enum members based on given country.
 *
 * @param country
 */
export function getVehicleClassEnumMembers(country: Country): THGVehicleClass[] {
    const res = [];

    if (country === Country.DE) {
        // add only certain vehicle classes
        res.push(
            THGVehicleClass.PV,
            THGVehicleClass.LCV,
            THGVehicleClass.N2,
            THGVehicleClass.M3,
            THGVehicleClass.L17,
        );
    } else if (country === Country.AT) {
        // add all vehicle classes
        for (const item in THGVehicleClass) {
            res.push(THGVehicleClass[item as keyof typeof THGVehicleClass]);
        }
    }

    return res;
}

/**
 * Map the ChargingPlugType enum to german text.
 *
 * @param p
 * @return textual representation
 */
export function getChargingPlugTypeText(p: THGChargingPlugType): string {
    switch (p) {
        case THGChargingPlugType.TYPE1:
            return 'Type 1';
        case THGChargingPlugType.TYPE2:
            return 'Type 2';
        case THGChargingPlugType.CCS:
            return 'CCS';
        case THGChargingPlugType.CHAdeMO:
            return 'CHAdeMO';
        case THGChargingPlugType.TESLA_SUPERCHARGER:
            return 'Tesla Supercharger';
        case THGChargingPlugType.SCHUKO:
            return 'Schuko';
        case THGChargingPlugType.CEE:
            return 'CEE';
    }
}

/**
 * Map the VehicleClass enum to german text.
 *
 * @param c
 */
export function getVehicleClassText(c: THGVehicleClass): string {
    switch (c) {
        case THGVehicleClass.PV:
            return 'M1';
        case THGVehicleClass.LCV:
            return 'N1';
        case THGVehicleClass.L17:
            return 'L1e-L7e';
        default:
            return c.toString().toUpperCase();
    }
}

/**
 * Format the probability coming from KÜS api (vrc).
 * @param n
 * @return percentage representation
 */
export function getProbPercent(n: number): string {
    return (n * 100).toLocaleString('de', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

/**
 * Utility function to remove the unneeded data from actual THGCar object.
 *
 * @param car THGCar object
 * @param country country
 * @param updateVin whether to update the vin number or not
 * @param updateYear whether to update the year or not
 * @param updateRegDate whether to update the registration date or not
 * @return THGUpdateCarData object
 */
export function createTHGCarUpdateData(
    car: THGCar,
    country: Country,
    updateVin = false,
    updateYear = false,
    updateRegDate = false,
): THGUpdateCarData {
    if (!car.user_id) {
        throw new Error('User ID is undefined');
    }

    const data: THGUpdateCarData = {
        status: car.status,
        license_plate: car.license_plate,
        drive_code: car.drive_code,
        vehicle_class: car.vehicle_class,
        make: car.make,
        type: car.type,
        initial_registration_date: car.initial_registration_date,
        first_name: car.first_name,
        name: car.name,
        city: car.city,
        zip_code: car.zip_code,
        street: car.street,
        user_id: car.user_id,
        note: car.note,
    };

    if (car.tags) {
        data.tags = [];

        for (const tag of car.tags) {
            if (tag._id) {
                data.tags.push(tag._id);
            }
        }
    }

    if (country === Country.DE && car.year > 2024 && car.is_first_year_registration !== undefined) {
        data.is_first_year_registration = car.is_first_year_registration;
    }

    if (country === Country.AT || (country === Country.DE && car.year > 2024)) {
        data.payoff_model = car.payoff_model;
        data.fix_payoff_amount = car.fix_payoff_amount;
    }

    if (country === Country.DE) {
        data.express_payoff_amount = car.express_payoff_amount;
        data.hsn_tsn = car.hsn_tsn;
    }

    if (country === Country.AT) {
        data.flex_payoff_amount = car.flex_payoff_amount;
        data.national_code = car.national_code;
        data.commercial_name = car.commercial_name;
        data.charging_plug_type = car.charging_plug_type;
        data.charging_point_address = car.charging_point_address;

        if (car.deregistration_date && car.deregistration_date instanceof Date) {
            // remove the time part from Date coming from
            data.deregistration_date = new Date(formatDate(car.deregistration_date, 'yyyy-MM-dd'));
        } else {
            data.deregistration_date = null;
        }
    }

    if (car.uba_submitted_date && car.uba_submitted_date instanceof Date) {
        // remove the time part from Date coming from
        data.uba_submitted_date = new Date(formatDate(car.uba_submitted_date, 'yyyy-MM-dd'));
    } else {
        data.uba_submitted_date = null;
    }

    if (updateVin) {
        data.vin = car.vin;
    }

    if (updateYear) {
        data.year = car.year;
    }

    if (updateRegDate) {
        data.registration_date = car.registration_date;
    }

    return data;
}

/**
 * Utility function to create user update data. Only contains necessary fields for updating.
 *
 * @param user THGUser object
 * @return update data as THGUserUpdateData
 */
export function createTHGUserUpdateData(user: THGUser): THGUserUpdateData {
    const { currentCountry } = useTHGGlobalStore();

    const data: THGUserUpdateData = {
        email: user.email,
        type: user.type,
        payee: user.payee,
        iban: user.iban,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        street: user.street,
        zip_code: user.zip_code,
        city: user.city,
        donation_ratio: user.donation_ratio,
        donation_projects: user.donation_projects,
        registration_limit: user.registration_limit,
        recommendation_email: user.recommendation_email,
    };

    if (data.type === THGUserType.COMPANY) {
        data.vat_number = user.vat_number;
        data.liable_tax = user.liable_tax;
        data.company_name = user.company_name;
    }

    if (currentCountry === Country.DE) {
        data.adac_member_number = user.adac_member_number || null;
    }

    return data;
}

/**
 * Generate the display name from a thg user object.
 *
 * @param user
 */
export function generateDisplayName(user: THGUser | undefined | null) {
    if (!user) {
        return '';
    }

    if (user.type === THGUserType.COMPANY && user.company_name) {
        return user.company_name;
    }

    if (user.type === THGUserType.PRIVATE && user.last_name) {
        return `${user.first_name} ${user.last_name}`;
    }

    return user.email;
}

/**
 * Process the action filter parameters and return the processed one.
 * It parses the date from strings e.g.
 * Returns a new object to not manipulate the actual reference in component.
 *
 * @param payload
 */
export function processActionPayload(
    payload:
        | THGCarUpdateStatusPayload
        | THGInfoMailPayload
        | THGCarOverrideCO2EquivalentPayload
        | THGCarOverrideStatusPayload,
):
    | THGCarUpdateStatusPayload
    | THGInfoMailPayload
    | THGCarOverrideCO2EquivalentPayload
    | THGCarOverrideStatusPayload {
    // copy the payload and the filter parameter with spread operator
    const processedPayload = { ...payload };
    const resultFilterParam: THGCarFilterParam | THGUserFilterParam = { ...payload.filter_params };

    if ('start_create_date' in payload.filter_params && payload.filter_params.start_create_date) {
        // start date, parse it
        (resultFilterParam as THGCarFilterParam).start_create_date = formatDate(
            payload.filter_params.start_create_date,
            'yyyy-MM-dd',
        );
    }

    if ('end_create_date' in payload.filter_params && payload.filter_params.end_create_date) {
        // end date parse it
        (resultFilterParam as THGCarFilterParam).end_create_date = formatDate(
            payload.filter_params.end_create_date,
            'yyyy-MM-dd',
        );
    }

    if (
        'vins' in payload.filter_params &&
        payload.filter_params.vins &&
        Array.isArray(payload.filter_params.vins)
    ) {
        // vins is an array, convert to string (backend needs a string)
        (resultFilterParam as THGCarFilterParam).vins = payload.filter_params.vins.join(',');
    }

    if (
        'uba_submitted_date' in payload.filter_params &&
        payload.filter_params &&
        payload.filter_params.uba_submitted_date
    ) {
        (resultFilterParam as THGCarFilterParam).uba_submitted_date = formatDate(
            payload.filter_params.uba_submitted_date,
            'yyyy-MM-dd',
        );
    }

    if (
        'tags' in payload.filter_params &&
        payload.filter_params.tags &&
        Array.isArray(payload.filter_params.tags)
    ) {
        // tags is an array consisting of THGTag objects, backend needs a string -> convert
        (resultFilterParam as THGCarFilterParam).tags = payload.filter_params.tags
            .map((tag) => tag._id)
            .join(',');
    }

    if (
        'not_tags' in payload.filter_params &&
        payload.filter_params.not_tags &&
        Array.isArray(payload.filter_params.not_tags)
    ) {
        // tags is an array consisting of THGTag objects, backend needs a string -> convert
        (resultFilterParam as THGCarFilterParam).not_tags = payload.filter_params.not_tags
            .map((tag) => tag._id)
            .join(',');
    }

    // set the copied filter parameter in copied payload object
    processedPayload.filter_params = resultFilterParam;

    // return the copy
    return processedPayload;
}
